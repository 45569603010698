<template>
  <div>
    <b-button
      size="md"
      style="
        background: #fdb632;
        min-width: 100px;
        border-radius: 8px;
        border: none;
      "
      @click="$refs.madeWithAIModal.open()"
    >
      <b-spinner small label="Small Spinner" v-if="saveLoading"></b-spinner>
      <span v-else>Match with AI!</span>
    </b-button>

    <Modal
      size="lg"
      ref="madeWithAIModal"
      id="MadeWithAI"
      :title="`<span class='px-4'>Let AI handle the matching your products</span>`"
    >
      <div class="px-4">
        <div class="" v-if="currView == 'step1'">
          <div v-if="getAllProducts.length > 0" class="d-flex flex-column">
            <div class="d-flex justify-content-between">
              <p>Choose your products <small>(Max 500 products only)</small></p>
              <p>Total Selected {{ selectedProducts.length }}</p>
            </div>

            <div style="max-height: 500px; overflow-y: auto">
              <b-table
                head-class="text-center"
                class="leadTable"
                :stickyColumn="true"
                hover
                :responsive="true"
                :fields="columns"
                :items="getAllProducts"
              >
                <template #cell(image)="data">
                  <LazyImage
                    v-if="checkFileType(data.item.image) != 'video'"
                    :src="
                      data.item.image
                        ? data.item.image
                        : 'https://cdn.shopify.com/s/files/1/0554/9676/9614/files/default.png?v=1663245344'
                    "
                    :key="data.item.image"
                    style="
                      height: 50px;
                      width: 50px;
                      border-radius: 50%;
                      cursor: pointer;
                    "
                    alt=""
                  />
                  <video
                    v-else
                    ref="videoRef"
                    :src="`${data.item.image}`"
                    style="
                      object-fit: cover;
                      border-radius: 6px;
                      height: 50px;
                      width: 50px;
                      cursor: pointer;
                    "
                    controls
                  ></video>
                </template>
                <template #cell(title)="data">
                  <div style="cursor: pointer; width: fit-content">
                    {{ data.item.title }}
                  </div>
                </template>
                <template #head(checkbox)>
                  <b-form-checkbox
                    v-model="isSelectAllToggle"
                    size="lg"
                    @input="toggleSelectAllProduct()"
                    class="checkbox"
                  ></b-form-checkbox>
                </template>
                <template #cell(checkbox)="data">
                  <b-form-checkbox
                    v-model="selectedProducts"
                    :value="data.item"
                    size="lg"
                    class="checkbox"
                  ></b-form-checkbox>
                </template>
              </b-table>
            </div>
            <div class="mt-2 d-flex justify-content-start">
              <button
                class="px-5 py-2 btn"
                style="background: #4d1b7e; border-radius: 8px; color: #fff"
                :disabled="!isStep1Valid"
                @click="currView = 'step2'"
              >
                Next
              </button>
            </div>
          </div>

          <div v-else>
            <p>No Products found</p>
          </div>
        </div>

        <div v-else>
          <div>
            <p>Tell the AI How you wish to match your products.</p>
            <b-form-textarea
              placeholder="How do you wish to match the product!"
              class="input-border-style"
              v-model="AiPromt"
              rows="5"
              resize
              max-rows="10"
            ></b-form-textarea>
          </div>
          <div class="mt-4">
            <small class="mb-3 d-block" v-if="saveLoading"
              >Note: This may take a while depending your total products.</small
            >
            <div class="d-flex justify-content-between align-items-center">
              <Button class=""  @click="currView = 'step1'" btnVariant="yellow"
              >Back</Button
            >
              <Button class="" :is-loading="saveLoading" @click="createAIMatch"
              >Create</Button
            >
            </div>
           
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Modal from "../../Dashboard/Layout/Modal.vue";
import Button from "../../Dashboard/Layout/Button.vue";
export default {
  props: ["products"],
  components: { Modal, Button },
  data() {
    return {
      isSelectAllToggle: false,
      selectedProducts: [],
      currView: "step1",
      saveLoading: false,
      AiPromt:
        "Recommend the best products relevant to the question and their options provided!",
      columns: [
        {
          key: "checkbox",
          label: "",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },
        {
          key: "image",
          label: "Image",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },
        {
          key: "title",
          label: "Title",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getQuizID"]),
    getAllProducts() {
      return this.products.all;
    },
    isStep1Valid() {
      return (
        this.selectedProducts.length > 0 && this.selectedProducts.length <= 500
      );
    },
  },
  methods: {
    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    toggleSelectAllProduct() {
      if (this.isSelectAllToggle) {
        this.selectedProducts = [
          ...new Set([...this.selectedProducts, ...this.getAllProducts]),
        ];
      } else this.selectedProducts = [];
    },
    async createAIMatch() {
      if (this.AiPromt == "") {
        this.$toasted.show("Please provide a prompt for AI!", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });

        return;
      }

      try {
        this.saveLoading = true;
        let data = {
          quizId: this.getQuizID,
          purpose: this.AiPromt,
          selectProductIds: this.selectedProducts.map((x) => x.id),
        };

        const response = await axios.post(
          "/quiz/ai-generate-product-match",
          data
        );

        if (response.status == 200) {
          this.$toasted.show("Match Completed!", {
            theme: "toasted-primary",

            position: "bottom-center",
            duration: 2000,
          });

          this.$refs.madeWithAIModal.close();
          const url =
            this.$router.history.current.path + "?selectedTab=product_match";
          window.top.location.href = url;
        }
      } catch (error) {
        console.log("Error", error);
        this.$toasted.show("Error Occured", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      } finally {
        this.saveLoading = false;
      }
    },
  },
};
</script>
<style></style>
